const V3_WORKFLOWS_URL = 'workflow/v3';

export const apiRouteV3 = {
  'autonomous-agent-query': {
    method: 'put',
    url: `/${V3_WORKFLOWS_URL}/conversation/{conversationId}/free-form-query`,
  },
  'init-autonomous-agent-conversation': {
    method: 'post',
    url: `/${V3_WORKFLOWS_URL}/conversation`,
  },
} as const;
