import customFetch from './customFetch';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL + 'solve'
  : 'https://solve-api-stage.forethought.ai/solve';

// Currently deployed widgets (that clients use) have a URL path with /solve.
// We have to remove it to make it valid for /workflows
export const baseURL = (REACT_APP_API_URL || '').replace(/\/solve$/, '');

export default function forethoughtFetch<AssumedResponseType>(
  input: Parameters<typeof fetch>[0],
  init: Parameters<typeof fetch>[1] & { data?: unknown },
): Promise<AssumedResponseType> {
  return customFetch(baseURL + input, {
    ...init,

    ...(init?.data ? { body: JSON.stringify(init.data) } : {}),

    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
    },
  });
}
