import { captureException, getCurrentScope } from '@sentry/react';
import ApiService from 'services/ApiService';
import { Primitive } from 'type-fest';

export function sendErrorToSentry({
  additionalContext = {},
  additionalTags = {},
  consoleError = false,
  conversationId,
  error,
}: {
  additionalContext?: Record<string, unknown>;
  additionalTags?: Record<string, Primitive>;
  consoleError?: boolean;
  conversationId?: string;
  error: Error | unknown;
}) {
  const scope = getCurrentScope();

  scope.setTags({
    ...additionalTags,
    conversationId,
    'solve-origin': ApiService.headers['solve-origin'],
  });

  scope.setContext('context', {
    ...additionalContext,
    conversationId,
    'solve-origin': ApiService.headers['solve-origin'],
  });

  if (error instanceof Error) {
    captureException(error);
  } else {
    captureException(new Error(`${error}`));
  }

  if (consoleError) {
    console.error(error);
  }
}
