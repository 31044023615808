import { ApiHeaders } from 'api/types';

/**
 * Build headers object to be used on API calls.
 * @param {string} apiKey - Api key for each org
 * @param {string} origin - url from where the app is being used
 * @param {string} workflowVersion - workflow version
 * @param {string} isBuilderPreview - indicates if the app is used as a preview inside workflow builder
 * @param {string} isDraft - indicates if the widget expects draft workflows in preview
 *
 * @returns Returns the correct headers object.
 *
 * */
export const buildHeaders = (
  apiKey: string,
  origin: string,
  isBuilderPreview: boolean,
  isSolveConfigPreview: boolean,
  workflowVersion: string,
  isDraft: boolean,
  isInteractiveEmailPreview: boolean,
) => {
  const isPreview =
    (isBuilderPreview && !workflowVersion) ||
    isInteractiveEmailPreview ||
    isSolveConfigPreview;

  let headers: ApiHeaders = {
    Authorization: `Bearer ${apiKey}`,
    'is-config-preview': isSolveConfigPreview,
    'is-draft': isPreview && isDraft,
    'is-preview': isPreview,
    'solve-origin': origin,
  };

  if (isBuilderPreview && workflowVersion)
    headers = { ...headers, 'workflow-config-version': workflowVersion };
  return headers;
};
