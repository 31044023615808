/* eslint-disable etc/no-deprecated -- Sentry v8 will break their performance
APIs, no idea how to migrate yet */

import * as Sentry from '@sentry/react';
import { spanStatusfromHttpCode } from '@sentry/react';

import { BaseApiService, ApiRoutes, RequestParameters } from './BaseApiService';
import { getCurrentHub, setTag } from '@sentry/react';
import { CustomFetchError } from 'api/customFetch';

class ApiService extends BaseApiService {
  isTracingEnabled = false;
  traceId: string | undefined;

  async request<T extends ApiRoutes>(
    serviceName: T,
    parameters: RequestParameters<T>,
  ) {
    if (!this.isTracingEnabled) {
      return super.request(serviceName, parameters);
    }

    const { method, url } = this.routes[serviceName];
    let transaction: Sentry.Transaction;
    // Start the transaction with the shared traceId if it exists
    if (!!this.traceId) {
      transaction = Sentry.startTransaction({
        name: url,
        traceId: this.traceId,
      });
    }
    // otherwise start the transaction and then set the shared traceId with this first transaction
    else {
      transaction = Sentry.startTransaction({ name: url });
      this.traceId = transaction.traceId;
    }
    // configure the scope and start the span
    getCurrentHub().configureScope(scope => scope.setSpan(transaction));
    const span = transaction.startChild({
      description: method + ' ' + url,
      op: 'http',
    });

    // add the trace id to the headers and set the trace id tags
    const parametersWithTraceHeader = {
      ...parameters,
      headers: {
        ...parameters.headers,
        'custom-sentry-trace': this.traceId,
      },
    };
    setTag('trace_id', this.traceId);
    try {
      const result = await super.request(
        serviceName,
        parametersWithTraceHeader,
      );

      span.setStatus('ok');

      return result;
    } catch (error: unknown) {
      if (error instanceof CustomFetchError) {
        span.setStatus(spanStatusfromHttpCode(error.status));
      } else {
        span.setStatus('unknown_error');
      }

      throw error;
    } finally {
      // we have to finish the span and transaction for the event to be sent to Sentry
      span.finish();
      transaction.finish();
    }
  }
}

export default new ApiService();
