const LIVE_CHAT_PREFIX = '/live-chat';

export const liveChatRoutes = {
  'delete-forethought-live-chat': {
    method: 'delete',
    url: `${LIVE_CHAT_PREFIX}/conversation/{conversationId}`,
  },
  'live-chat-resolve-conversation': {
    method: 'put',
    url: `${LIVE_CHAT_PREFIX}/conversation/{conversationId}/helpdesk-conversation/{helpdeskConversationId}/resolve`,
  },
  'live-chat-send-agent-message': {
    method: 'post',
    url: `${LIVE_CHAT_PREFIX}/conversation/{conversationId}/helpdesk-conversation/{helpdeskConversationId}/agent/message`,
  },
  'live-chat-send-message': {
    method: 'post',
    url: `${LIVE_CHAT_PREFIX}/conversation/{conversationId}/helpdesk-conversation/{helpdeskConversationId}/message`,
  },
  'update-forethought-live-chat-helpdesk-conversation-id': {
    method: 'put',
    url: `${LIVE_CHAT_PREFIX}/conversation/{conversationId}/helpdesk-conversation-id`,
  },
} as const;
