interface ForethoughtError {
  message: string;
  status: number;
}

interface FastApiError {
  detail: string;
}

export class CustomFetchError extends Error {
  status: number;
  response: Response | undefined;
  data: ForethoughtError | unknown;
  method: string;
  url: string;

  constructor(
    message: string,
    {
      data,
      method,
      response,
      status,
      url,
    }: {
      data: ForethoughtError | unknown;
      method: string;
      response: Response | undefined;
      status?: number;
      url: string;
    },
  ) {
    super(message);

    Object.setPrototypeOf(this, CustomFetchError.prototype);

    this.status = status ?? response?.status ?? 0;
    this.response = response;
    this.data = data;
    this.method = method;
    this.url = url;
  }
}

function isForethoughtError(error: unknown): error is ForethoughtError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  );
}

function isFastApiError(error: unknown): error is FastApiError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'detail' in error &&
    typeof error.detail === 'string'
  );
}

export default async function customFetch<AssumedResponseType>(
  ...parameters: Parameters<typeof fetch>
): Promise<AssumedResponseType> {
  const [input, init] = parameters;
  const url = input instanceof Request ? input.url : String(input);
  const method = init?.method || 'get';

  let response: Response;

  try {
    response = await fetch(...parameters);
  } catch (error) {
    if (error instanceof Error) {
      throw new CustomFetchError(error.message, {
        data: null,
        method,
        response: undefined,
        url,
      });
    }

    throw error;
  }

  if (!response.ok) {
    const contentType = response.headers.get('content-type');

    if (contentType?.includes('application/json')) {
      const data = await response.json();

      if (isForethoughtError(data)) {
        throw new CustomFetchError(data.message, {
          data,
          method,
          response,
          status: data.status,
          url: response.url,
        });
      }

      if (isFastApiError(data)) {
        throw new CustomFetchError(`FastAPI error: ${data.detail}`, {
          data,
          method,
          response,
          url: response.url,
        });
      }

      throw new CustomFetchError('Unrecognizable JSON response', {
        data,
        method,
        response,
        url: response.url,
      });
    }

    const data = await response.text();

    if (contentType?.includes('text/plain')) {
      throw new CustomFetchError(data, {
        data,
        method,
        response,
        url: response.url,
      });
    }

    throw new CustomFetchError(`Non-JSON response: ${contentType}`, {
      data,
      method,
      response,
      url: response.url,
    });
  }

  /**
   * Attachment uploads to a presigned AWS url will get a successful response in XML format
   * instead of JSON. Since the response is successful, it will not be caught in the block
   * above. As long as the response is ok, we want to catch the error and return an empty
   * object.
   *
   * See https://stackoverflow.com/questions/37402284/get-json-response-using-aws-presigned-url
   */
  try {
    const json = await response.json();
    return json as AssumedResponseType;
  } catch (error) {
    return {} as AssumedResponseType;
  }
}

export type CustomFetchErrorObject = {
  message: string;
  method: string;
  response_data: unknown;
  status: number;
  url: string;
};

function customFetchErrorToObject(
  error: unknown | CustomFetchError,
): CustomFetchErrorObject | undefined {
  if (error instanceof CustomFetchError) {
    return {
      message: error.message,
      method: error.method,
      response_data: error.data,
      status: error.status,
      url: error.url,
    } as const;
  }
}

export function isCustomFetchErrorObject(
  value: unknown,
): value is CustomFetchErrorObject {
  return (
    typeof value === 'object' &&
    value !== null &&
    'message' in value &&
    'method' in value &&
    'response_data' in value &&
    'status' in value &&
    'url' in value
  );
}

/* eslint-disable-next-line @typescript-eslint/ban-types -- Redux Toolkit
doesn't export the necessary types */
export function rejectWithCustomFetchError<T extends Function>(
  error: unknown,
  rejectWithValue: T,
) {
  const errorObject = customFetchErrorToObject(error);

  if (errorObject) {
    return rejectWithValue(errorObject);
  }

  throw error;
}
