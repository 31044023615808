import {
  ApiHeaders,
  AutonomousAgentConversationResponse,
  AutonomousAgentQueryRequest,
  CsatData,
  CsatTranslatedText,
  IntentConversationResponse,
  InitConversationInterface,
  InitIntentConversationRequest,
  InitializeAutonomousAgentConversationRequest,
  PresignedUrlRequest,
  ResetConversationInterface,
  RestartConversationRequest,
  RestartIntentConversationRequest,
  SendFreeFormQueryRequest,
  SendTrackingActionArgs,
  UpdateIntentConversationRequest,
  WidgetConfigRequest,
  LiveChatSendMessageRequest,
  UpdateHelpdeskConversationRequest,
  SendFeedbackRequest,
  SubmitInteractiveEmailDeflectionFeedbackRequest,
  SubmitInteractiveEmailDeflectionFeedbackResponse,
  WorkflowConfigResponse,
  WidgetComponentsResponse,
  LiveChatSendMessageResponse,
  SendConversationAlertRequest,
} from 'api/types';
import { setSentryContext, getSentryTags } from 'api/utils';
import { captureException } from '@sentry/react';
import { buildHeaders } from '../utils/buildHeaders';
import { apiRoute } from 'api/requests';
import { apiRouteV2 } from 'api/requestsV2';
import { apiRouteV3 } from 'api/requestsV3';
import forethoughtFetch from 'api/forethoughtFetch';
import mapValues from 'just-map-values';
import { CustomFetchError } from 'api/customFetch';
import { liveChatRoutes } from 'api/liveChat';
import { type Primitive } from 'type-fest';
import { postMessage } from 'utils/postMessage';

interface RouteDataMapping {
  'autonomous-agent-query': {
    data: AutonomousAgentQueryRequest;
    returnValue: AutonomousAgentConversationResponse;
    urlValue: { conversationId: string };
  };
  'continue-autoflow-intent': {
    data: never;
    returnValue: IntentConversationResponse;
    urlValue: { conversationId: string };
  };
  'delete-forethought-live-chat': {
    data: null;
    returnValue: void;
    urlValue: { conversationId: string };
  };
  'get-translated-csat-text': {
    data: null;
    returnValue: CsatTranslatedText;
    urlValue: { conversationId: string };
  };
  'get-widget-components': {
    data: null;
    returnValue: WidgetComponentsResponse;
    urlValue: { conversationId: string };
  };
  'init-autonomous-agent-conversation': {
    data: InitializeAutonomousAgentConversationRequest;
    returnValue: AutonomousAgentConversationResponse;
    urlValue: null;
  };
  'init-intent-conversation': {
    data: InitIntentConversationRequest;
    returnValue: IntentConversationResponse;
    urlValue: null;
  };
  'interactive-email-deflection-feedback': {
    data: SubmitInteractiveEmailDeflectionFeedbackRequest;
    returnValue: SubmitInteractiveEmailDeflectionFeedbackResponse;
    urlValue: { conversationId: string };
  };
  'live-chat-resolve-conversation': {
    data: null;
    returnValue: void;
    urlValue: { conversationId: string; helpdeskConversationId: string };
  };
  'live-chat-send-agent-message': {
    data: LiveChatSendMessageRequest;
    returnValue: void;
    urlValue: { conversationId: string; helpdeskConversationId: string };
  };
  'live-chat-send-message': {
    data: LiveChatSendMessageRequest;
    returnValue: LiveChatSendMessageResponse;
    urlValue: { conversationId: string; helpdeskConversationId: string };
  };
  'restart-conversation': {
    data: RestartConversationRequest;
    returnValue: InitConversationInterface;
    urlValue: { conversationId: string };
  };
  'restart-intent-conversation': {
    data: RestartIntentConversationRequest;
    returnValue: ResetConversationInterface;
    urlValue: { conversationId: string };
  };
  'send-conversation-alert': {
    data: SendConversationAlertRequest;
    returnValue: void;
    urlValue: { conversationId: string };
  };
  'send-conversation-feedback': {
    data: SendFeedbackRequest;
    returnValue: IntentConversationResponse;
    urlValue: { conversationId: string };
  };
  'send-free-form-query': {
    data: SendFreeFormQueryRequest;
    returnValue: IntentConversationResponse;
    urlValue: { conversationId: string };
  };
  'submit-intent-csat': {
    data: CsatData;
    returnValue: { success: boolean };
    urlValue: { conversationId: string };
  };
  'tracking-events': {
    data: SendTrackingActionArgs;
    returnValue: void;
    urlValue: null;
  };
  'update-forethought-live-chat-helpdesk-conversation-id': {
    data: { helpdesk_conversation_id: string };
    returnValue: void;
    urlValue: { conversationId: string };
  };
  'update-helpdesk-conversation': {
    data: UpdateHelpdeskConversationRequest;
    returnValue: void;
    urlValue: { conversationId: string };
  };
  'update-intent-conversation': {
    data: UpdateIntentConversationRequest;
    returnValue: IntentConversationResponse;
    urlValue: { conversationId: string };
  };
  'upload-file': {
    data: PresignedUrlRequest;
    returnValue: {
      content_type: string;
      temp_file_download_link?: string;
      temp_file_upload_link: string;
    };
    urlValue: null;
  };
  'widget-config': {
    data: WidgetConfigRequest;
    returnValue: WorkflowConfigResponse;
    urlValue: null;
  };
}

export type ApiRoutes =
  | keyof typeof apiRoute
  | keyof typeof apiRouteV2
  | keyof typeof apiRouteV3
  | keyof typeof liveChatRoutes;

export type RequestParameters<T extends ApiRoutes> = {
  headers?: HeadersInit;

  // If `urlValue` is `null` in `RouteDataMapping` (which means that this
  // request doesn't use URL values), this conditional type will allow us to
  // omit `urlValues` when calling `request()`, as opposed to having to specify
  // `urlValues: {}` or `urlValues: null`:
} & (RouteDataMapping[T]['urlValue'] extends null
  ? {
      urlValues?: never;
    }
  : {
      urlValues: RouteDataMapping[T]['urlValue'];
    }) &
  // Same for `data`:
  (RouteDataMapping[T]['data'] extends null
    ? {
        data?: never;
      }
    : {
        data: RouteDataMapping[T]['data'];
      });

export class BaseApiService {
  instance: BaseApiService | null = null;

  fetch: typeof forethoughtFetch = forethoughtFetch;

  headers: ApiHeaders = {
    Authorization: '',
    'is-config-preview': false,
    'is-draft': true,
    'is-preview': false,
    'solve-origin': '',
  };

  sentryTags: {
    key: string;
    value: Primitive;
  }[] = [];

  routes = {
    ...apiRoute,
    ...apiRouteV2,
    ...apiRouteV3,
    ...liveChatRoutes,
  };

  constructor() {
    if (this.instance === null) {
      this.instance = this;
    }

    return this.instance;
  }

  init({
    apiKey,
    isBuilderPreview,
    isDraft,
    isInteractiveEmailPreview,
    isSolveConfigPreview,
    origin,
    workflowVersion,
  }: {
    apiKey: string;
    isBuilderPreview: boolean;
    isDraft: boolean;
    isInteractiveEmailPreview: boolean;
    isSolveConfigPreview: boolean;
    origin: string;
    workflowVersion: string;
  }) {
    this.headers = buildHeaders(
      apiKey,
      origin,
      isBuilderPreview,
      isSolveConfigPreview,
      workflowVersion,
      isDraft,
      isInteractiveEmailPreview,
    );
  }

  async request<T extends ApiRoutes>(
    serviceName: T,
    { data = null, headers, urlValues = null }: RequestParameters<T>,
  ): Promise<RouteDataMapping[T]['returnValue']> {
    this.sentryTags = await getSentryTags();
    const { method, url } = this.routes[serviceName];

    try {
      const res = await this.fetch<RouteDataMapping[T]['returnValue']>(
        this.tokenizeUrl(url, urlValues),
        {
          data,
          headers: mapValues({ ...this.headers, ...headers }, String),
          method,
        },
      );

      return res;
    } catch (error) {
      this.handleError({ error, requestData: data, serviceName });
    }
  }

  private handleError({
    error,
    requestData,
    serviceName,
  }: {
    error: unknown;
    requestData: unknown;
    serviceName: ApiRoutes;
  }) {
    if (error instanceof CustomFetchError) {
      setSentryContext({
        errorMessage: error.message,
        origin: this.headers['solve-origin'],
        requestData,
        responseData: error.data,
        tags: [
          ...this.sentryTags,
          {
            key: 'responseStatus',
            value: error.status,
          },
        ],
      });
      captureException(error);
    }

    switch (serviceName) {
      case 'widget-config':
        postMessage({
          error:
            'Invalid configuration. Please verify API key is correct and all necessary context variables are defined.',
          event: 'forethoughtWidgetError',
        });
    }

    throw error;
  }

  private tokenizeUrl = (
    url: string,
    params: Record<string, string> | null,
  ) => {
    let result: string = url;

    if (params !== null) {
      Object.entries(params).forEach(([key, val]) => {
        if (result.split(`{${key}}`).length === 1) {
          throw new Error(`Cannot tokenize. Url param ${key} does not exit`);
        }
        result = result.split(`{${key}}`).join(val);
      });
    }

    return result;
  };
}
