const KEY = 'ft-zendesk-chat-on-end-delay';

const parseDelay = (delay: unknown): number | null => {
  const parsedDelay = Number(delay);
  if (isNaN(parsedDelay)) {
    return null;
  }
  return parsedDelay;
};

/**
 * @param delay - The delay in seconds.
 *
 * this also removes the delay before setting it so that different widget handoffs don't
 * interfere with each other
 */
export const setZendeskChatOnEndDelay = (delay: unknown) => {
  try {
    removeZendeskChatOnEndDelay();
    const parsedDelay = parseDelay(delay);
    if (parsedDelay) {
      localStorage.setItem(KEY, String(parsedDelay * 1000));
    }
  } catch {
    // Continue regardless of error
  }
};

export const getZendeskChatOnEndDelay = (): number | null => {
  try {
    return parseDelay(localStorage.getItem(KEY));
  } catch {
    return null;
  }
};

export const removeZendeskChatOnEndDelay = () => {
  try {
    localStorage.removeItem(KEY);
  } catch {
    // Continue regardless of error
  }
};
