import { type RootState } from 'reducers/root';
import { type Middleware } from 'redux';

type LogRocket = typeof import('logrocket');
type LazyLogRocket = Pick<
  LogRocket,
  'init' | 'identify' | 'getSessionURL' | 'reduxMiddleware' | 'sessionURL'
>;
type TypedMiddleware = Middleware<unknown, RootState>;

const state: {
  isLoading: boolean;
  originalLogRocket: LogRocket | undefined;
  originalLogRocketMiddleware: TypedMiddleware | undefined;
  reduxMiddlewareOptions:
    | Parameters<LazyLogRocket['reduxMiddleware']>[0]
    | undefined;
} = {
  isLoading: false,
  originalLogRocket: undefined,
  originalLogRocketMiddleware: undefined,
  reduxMiddlewareOptions: undefined,
};

const defaultSessionUrl =
  'Must call LogRocket.init() before sessionURL becomes available.';

async function waitForOriginalLogRocket() {
  while (state.isLoading) {
    await new Promise<void>(resolve => {
      setTimeout(resolve, 100);
    });
  }
}

const lazyLogRocketMiddleware: TypedMiddleware = store => next => action => {
  // Sometimes `action` is `undefined`, and Redux throws
  // "Uncaught Error: Actions must be plain objects."
  // This check will prevent the error:
  if (!action) {
    return;
  }

  if (state.originalLogRocketMiddleware) {
    return state.originalLogRocketMiddleware(store)(next)(action);
  }

  return next(action);
};

const lazyLogRocket: LazyLogRocket = {
  async getSessionURL(callback) {
    await waitForOriginalLogRocket();

    if (state.originalLogRocket) {
      state.originalLogRocket.getSessionURL(callback);

      return;
    }

    callback(defaultSessionUrl);
  },

  async identify(uid, traits?: Parameters<LazyLogRocket['identify']>[0]) {
    await waitForOriginalLogRocket();

    if (state.originalLogRocket) {
      if (typeof uid === 'string') {
        state.originalLogRocket.identify(uid, traits);

        return;
      }

      state.originalLogRocket.identify(uid);
    }
  },

  async init(...parameters) {
    state.isLoading = true;

    state.originalLogRocket = (
      await import('logrocket' /* webpackChunkName: "lazy-logrocket" */)
    ).default;

    state.isLoading = false;

    state.originalLogRocketMiddleware = state.originalLogRocket.reduxMiddleware(
      state.reduxMiddlewareOptions,
    );

    state.originalLogRocket.init(...parameters);
  },

  reduxMiddleware(reduxMiddlewareOptions): TypedMiddleware {
    state.reduxMiddlewareOptions = reduxMiddlewareOptions;

    return lazyLogRocketMiddleware;
  },

  get sessionURL() {
    return state.originalLogRocket
      ? state.originalLogRocket.sessionURL
      : defaultSessionUrl;
  },
};

export default lazyLogRocket;
